/* src/components/Page.css */

@tailwind components;
@tailwind utilities;


.weblink {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: "Inter", sans-serif;
    padding: 10px;
  }
  
  .container {
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  }

  .dark .container {
    background: #202020;
    /* background: #2c3e50; */
    color: white;
  }
  
  .profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border: #343a40 3px solid;
    transition: border 0.3s ease;
  }

  .dark .profile-img {
    border: #ecf0f1 3px solid;
  }
  
  h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #343a40;
    transition: color 0.3s ease;
  }

  .dark h1 {
    color: #ecf0f1;
  }
  
  p {
    margin-bottom: 20px;
    color: #6c757d;
  }

  .dark p {
    color: #bdc3c7;
  }

  .smalltext {
    font-size: smaller;
    color: #6c757d;
    margin-bottom: -5px;
}
  
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  margin: 0 10px;
  color: #343a40;
  font-size: 1.5em;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
  color:#65b7ff;
}

.dark .social-links a:hover {
  color: #65b7ff;
}

.dark .social-links a {
  color: #ecf0f1;
}

footer {
  margin-top: 25px;
  color: #6c757d;
  font-size: 0.8em;
}

.dark footer {
  color: #bdc3c7;
}