/* src/components/Link.css */
.link {
  width: 100%;
  margin: 10px 0;
  transition: transform 0.2s;
}

.link a {
  display: inline-block;
  width: 100%;
  max-width: 400px;
  text-decoration: none;
  color: #343a40;
  background-color: #ffffff;
  padding: 15px 1px;
  border-radius: 15px;
  font-size: 1.2em;
  border: 2px solid #343a40;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.link a:hover {
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px #0221853b;
  color: #65b7ff;
  border: 2px solid #65b7ff;
}

.dark .link a:hover {
  background-color: #202020;
  /* background-color: #2c3e50; */
  box-shadow: 0 4px 8px #0221856d;
  color: #65b7ff;
  border: 2px solid #65b7ff;
}

.dark .link a {
  color: #ecf0f1;
  background-color: #202020;
  /* background-color: #2c3e50; */
  border: 2px solid #ecf0f1;
}

.link:hover {
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .link a {
    font-size: 1em;
    padding: 10px 1px;
  }
}
