/* src/App.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to right, #022185, #65b7ff);
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.dark .App {
  background: linear-gradient(to right, #01124a, #31597d);
  /* background: linear-gradient(to right, #243241, #4ca1af); */
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
